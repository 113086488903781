
import React from "react";
import './Contact.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="contact-header">
        <h1>What can us do for you?</h1>
        <p>
          We are ready to work on a project of any complexity, whether it’s commercial or residential.
        </p>
      </div>
      <form className="contact-form">
        <div className="form-row">
          <input type="text" placeholder="Your Name*" required />
          <input type="email" placeholder="Email*" required />
        </div>
        <div className="form-row">
          <input type="text" placeholder="Reason for Contacting*" required />
          <input type="text" placeholder="Phone" />
        </div>
        <textarea placeholder="Message" required></textarea>
        <p className="required-field"><span>* </span>indicates a required field</p>
        <button type="submit">Submit</button>
      </form>
      <footer className="contact-footer">
        <div className="contact-info">
          <p><strong>ADDRESS:</strong> 6391 Elgin St. Celina, Delaware 10299</p>
          <p><strong>PHONE:</strong> +84 1102 2703</p>
          <p><strong>EMAIL:</strong> hello@thebox.com</p>
        </div>

        <div className="newsletter">
          <p><strong>NEWSLETTER:</strong></p>
          <div className="newsletter-form">
            <input type="email" placeholder="Your email here" />
            <button type="submit">Subscribe</button>
          </div>
          <div className="social">
            <strong>SOCIAL:</strong>
          
         </div>
         <div className="social-icons">
              <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
              <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
            </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>TheBox Company © 2022. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Contact;
