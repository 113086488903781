import React from 'react';
import './Services.css';

const services = [
  { title: "Construction", icon: "🏗" },
  { title: "Renovation", icon: "🛠" },
  { title: "Consultation", icon: "💼" },
  { title: "Repair Services", icon: "🔧" },
  { title: "Architecture", icon: "🖌" },
  { title: "Electric", icon: "💡" }
];

function Services() {
  return (
    <div className="services">
      <h2>Services</h2>
      <div className="service-grid">
        {services.map((service, index) => (
          <div key={index} className={`service-box ${service.title === "Renovation" || service.title === "Repair Services" || service.title === "Electric" ? "blue-background" : ""}`}>

            <div className="service-icon">{service.icon}</div>
            <div className="service-title">{service.title}</div>
            {service.details && (
              <div className="service-details">
                <div>{service.details.projects} Projects Completed</div>
                <div>{service.details.happyClients} Happy Clients</div>
                <div>{service.details.yearsExperience} Years Experience</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;