import React from 'react';
import './Experience.css'; 

function App() {
    return (
      <div className="App">
        <header className="experience-section">
        <div className="statistics">
      <div className="stat-item123">
        <img src="/images/projects.png" alt="Projects Completed" className="icon123" />
        <h2>123</h2>
        <p>Projects Completed</p>
      </div>
      <div className="stat-item84">
        <img src="/images/happy client.png" alt="Happy Clients" className="icon84" />
        <h2>84</h2>
        <p>Happy Clients</p>
      </div>
      <div className="stat-item30">
        <h2>30</h2>
        <p>Years in Business</p>
        <img src="/images/years in.png" alt="Years in Business" className="icon30" />
        

      </div>
      <div className="stat-item37">
        <img src="images/Awards.png" alt="Awards Win" className="icon37" />
       
        <h2>37</h2>
        <p>Awards Win</p>
        
      </div>
    </div>
          
    
          <div className="experience-details">
            <h2>30 Years Experience</h2>
            <p>
              Our company has been the leading provider of construction services to clients
              throughout the USA since 1988.
            </p>
            <button className="contact-button">Contact Us</button>
          </div>
        </header>
  
        <section className="consultation-section">
          <div className="consultation-text">
            
            <h3>Free consultation with exceptional quality</h3>
            <p>Just one call away: +84 1102 2703</p>
          </div>
          <button className="consultation-button">Get your consultation</button>
        </section>
      </div>
    );
  }
  
  export default App;



