import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1><b>Building things is our mission.</b></h1>
      
        <div className="project">
          <h2>Feature Projects</h2>
          <p>The National University of Architecture</p>
          <div className="navigation">
            <button>← Back</button>
            <button>Next →</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;