import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <img src="https://cdn.pixabay.com/photo/2024/08/03/13/05/ai-generated-8942076_640.png" className="about-us-image"/>
      <div className="about-us-text-container">
        <h2><b>About us</b></h2>
        <p>
          For more than 30 years we have been delivering world-class construction
          and we've built many lasting relationships along the way.
        </p>
        <p>
          We’ve matured into an industry leader and trusted resource for those
          seeking quality, innovation and reliability when building in the U.S.
        </p>
        <button>More on Our History</button>
      </div>
    </div>
  );
};

export default AboutUs;