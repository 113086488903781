import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Reputation from './components/Reputation';
import Services from './components/Services';
import Experience from './components/Experience';
import Project from './components/Project';
import Contact from './components/Contact';



// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Reputation />
      <AboutUs/>
      <Services/>
      <Experience/>
      <Project/>
      <Contact/>   
      

      
    </div>
  );
}

export default App;

