import React from 'react';
import './Project.css';

const projects = [
  {
    name: 'Wildstone Infra Hotel',
    address: '2715 Ash Dr. San Jose, South Dakota',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzNRvPiAn09IpRCyn0eRjFw_3jmqoCkFOfaQ&s',
  },
  {
    name: 'Wish Stone Building',
    address: '2972 Westheimer Rd. Santa Ana, Illinois',
    image: 'https://images.pexels.com/photos/2599538/pexels-photo-2599538.jpeg?cs=srgb&dl=pexels-brett-sayles-2599538.jpg&fm=jpg',
  },
  {
    name: "Mr. Parkinson's House",
    address: '3517 W. Gray St. Utica, Pennsylvania',
    image: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    name: 'Oregano Height',
    address: '2464 Royal Ln. Mesa, New Jersey',
    image: 'https://images.pexels.com/photos/2997636/pexels-photo-2997636.jpeg?cs=srgb&dl=pexels-elsimage-2997636.jpg&fm=jpg',
  },
];

const App = () => {
  return (
    <div className="container">
      <div className="sidebar">
        <h2>Projects</h2>
        <ul>
          <li className="active">All</li>
          <li>Commercial</li>
          <li>Residential</li>
          <li>Other</li>
        </ul>
      </div>

      <div className="main-content">
        <div className="project-grid">
          {projects.map((project, index) => (
            <div className="project-card" key={index}>
              <img src={project.image} alt={project.name} />
              <div className="project-info">
                <h3>{project.name}</h3>
                <p>{project.address}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          <button className="back-button">← Back</button>
          <div className="dots">
            <span className="dot active"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          <button className="next-button">Next →</button>
        </div>
      </div>
    </div>
  );
};

export default App;
